
import { defineComponent, reactive, toRefs } from 'vue'
import { Toast, Notify } from 'vant'
import demandApi from '@/axios/api/demand'
import { useRouter, useRoute } from 'vue-router'
import store from '@/store/index'
export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const onClickLeft = () => history.back()
    const pageData = reactive({
      phone: '',
      code: '',
      sendCodeText: '获取验证码',
    })
    pageData.phone = route.query.phone as string
    //发送验证码
    const sendCode = () => {
      if (pageData.sendCodeText !== '获取验证码') {
        return
      }
      if (
        !pageData.phone ||
        !/^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/.test(
          pageData.phone
        )
      ) {
        Toast('手机号为空或手机号格式不正确')
        return
      }
      demandApi
        .SendCode({ phone: pageData.phone })
        .then((res) => {
          Notify({ type: 'success', message: '获取成功请查看' })
          let time = +res
          let timer = setInterval(() => {
            if (time > 0) {
              pageData.sendCodeText = --time + '秒'
            } else {
              //倒计时 时间到 重新绑定验证事件  清楚定时器
              pageData.sendCodeText = '获取验证码'
              clearInterval(timer)
            }
          }, 1000)
        })
        .catch((err) => {
          Notify({ type: 'warning', message: err })
        })
    }
    const onSubmit = () => {
      if (!pageData.phone || !pageData.code) {
        Toast('手机号或验证码为空')
        return
      }
      demandApi
        .Approve({
          phone: pageData.phone,
          code: pageData.code,
        })
        .then(() => {
          console.log(store.state.demandForm)
          if (store.state.demandForm.companyName) {
            demandApi
              .DemandAdd(store.state.demandForm)
              .then(() => {
                Notify({ type: 'success', message: '提交成功' })
                store.commit('setDemandForm', {})
                router.push(`/success`)
              })
              .catch((err) => {
                Notify({ type: 'warning', message: err })
              })
          } else {
            router.go(-1)
          }
        })
        .catch((err) => {
          Notify({ type: 'warning', message: err })
        })
    }
    return {
      onClickLeft,
      ...toRefs(pageData),
      sendCode,
      onSubmit,
    }
  },
})
